import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import cosude from "@images/cosude-logo.png";
import idb from "@images/idb-logo.png";
import quest from "@images/questn-logo.png"
import adorno from "@images/fotos/img1.jpg";

const Wrapper = styled.div`
padding-top: 1rem;
background-position: left;
background-size: contain;
background-repeat: no-repeat;
display: flex;
flex-direction: row;
align-content: center;
justify-content: flex-end;
`;

const Adorno = styled.div`
position: absolute;
top: 0;
left: 0;
height:100%;
background-position-x:-120%;

-webkit-clip-path: polygon(0 0, 42% 0, 32% 100%, 0 100%);
clip-path: polygon(0 0, 42% 0, 32% 100%, 0 100%);

@media only screen and (min-width:768px){
  height:700px;
  width: 100%;
  background-position-x:-400px;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  width: 100%;
  background-position-x:-400px;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
`;


const Content = styled.div`
text-align:center;
@media only screen and (min-width:768px){
  width: 100%;
  max-width: 700px;
  text-align:justify;
}
@media only screen and (min-width:1200px){
  width: 100%;
  max-width: 800px;
  text-align:justify;
}
width:100%;
background-color: white;
height:100%;
padding: 10px;





`;




const ImageContainer =styled.div`
padding-top: 2rem;
padding-bottom: 2rem;
display: flex;
flex-direction: column;






@media (min-width:1024px){
  flex-direction: row;
  justify-content: space-around;
  
}
`;

const IndexPage = () => {




  return (
    <TemplatePage1
      title={"Financiamiento"}
      adorno={<Adorno/>}
      content={(
        <Wrapper >
          <Content>
          <p>
          La red QuEST LAC cuenta con el apoyo financiero de la Agencia Suiza para el Desarrollo y la Cooperación (COSUDE) y el Banco Interamericano de Desarrollo (BID). 
            </p>
            <ImageContainer>
            <img className={"h-auto p-4 w-48 m-auto"} src={idb} alt= {idb}/>
          <img className={"h-auto p-4 w-90 m-auto"} src={cosude} alt= {cosude}/>  
            </ImageContainer>
          
          <p>
          El contenido es responsabilidad exclusiva de QuEST LAC y no necesariamente representa las opiniones oficiales de los financiadores.
          </p>
          </Content>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage






